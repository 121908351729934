import debounce = require('lodash.debounce');
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from '../Header/Header';
import { Controls } from '../Controls/Controls';
import { Card } from '../Card/Card';
import { Entry } from '../types';
import { IndeterminateProgress } from '../IndeterminateProgress/IndeterminateProgress';
import { API_ROUTE_PATH } from '../utils/constants';
import {
  EntryNotExists,
  FinishedSessionError,
  LS_SESSION_ID,
  NoSessionIdError,
} from './Posts.helpers';
import { useViewport } from '../utils/hooks';
import * as styles from './Posts.module.scss';

export const Posts: FC = () => {
  const [entries, setEntries] = useState<Entry[]>([]);
  const [entry, setEntry] = useState<Entry | null>(null);
  const [currentEntryIndex, setCurrentEntryIndex] = useState(-1);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isBigViewport = useViewport();
  const location = useLocation();
  const [isPrefetching, setIsPrefetching] = useState(false);

  const handleClickPrev = useCallback(
    () => setCurrentEntryIndex(state => Math.max(0, state - 1)),
    [],
  );
  const handleClickNext = useCallback(() => setCurrentEntryIndex(state => state + 1), []);

  useEffect(() => {
    const postId = location.state as string | undefined;
    const sessionId = localStorage.getItem(LS_SESSION_ID);

    const bootstrap = postId
      ? () => {
          window.history.replaceState(null, undefined, window.location.pathname);
          return fetchEntry(postId);
        }
      : () => Promise.resolve();

    const onFinally = () => {
      setIsPrefetching(false);
      setCurrentEntryIndex(0);
    };

    if (sessionId) {
      bootstrap()
        .finally(() => {
          setIsPrefetching(true);
          return fetchEntries();
        })
        .finally(onFinally);
    } else {
      bootstrap()
        .finally(() => {
          setIsPrefetching(true);
          return setupEntries();
        })
        .finally(onFinally);
    }

    const body = document.querySelector('body');

    const listenHotkeys = debounce((e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        setCurrentEntryIndex(state => (state === 0 ? state : state - 1));
      }

      if (e.key === 'ArrowRight') {
        setCurrentEntryIndex(state => state + 1);
      }
    }, 145);
    body.addEventListener('keydown', listenHotkeys);

    return () => body.removeEventListener('keydown', listenHotkeys);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    if (entries[currentEntryIndex]) {
      processEntry(entries[currentEntryIndex]);
    } else if (currentEntryIndex >= entries.length && !isPrefetching) {
      fetchEntries();
    }
  }, [currentEntryIndex, entries]);

  const updateSessionId = useCallback((id: string) => localStorage.setItem(LS_SESSION_ID, id), []);

  const processEntry = useCallback((entry: Entry) => {
    setCurrentSlideIndex(0);
    setEntry(entry);
    setIsLoading(false);
  }, []);

  const setupEntries = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await fetch(API_ROUTE_PATH.setupSession(), {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({}),
      });
      const data: { session_id: string; entries: Entry[] } = await response.json();
      updateSessionId(data.session_id);

      setEntries(state => [...state, ...data.entries]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEntry = async (entryId: string): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await fetch(API_ROUTE_PATH.getEntry(entryId));
      const data: Entry = await response.json();

      if (!data) {
        throw new EntryNotExists(`Entry ${entryId} does not exist`);
      }

      setEntries(state => [...state, data]);
    } catch (error) {
      if (error instanceof NoSessionIdError || error instanceof FinishedSessionError) {
        setupEntries();
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEntries = async (): Promise<void> => {
    const sessionId = localStorage.getItem(LS_SESSION_ID);

    setIsLoading(true);

    try {
      if (!sessionId) {
        throw new NoSessionIdError();
      }

      const response = await fetch(API_ROUTE_PATH.getEntries(), {
        headers: { 'session-id': sessionId },
      });
      const data: Entry[] = await response.json();

      if (data.length === 0) {
        throw new FinishedSessionError();
      }

      setEntries(state => [...state, ...data]);
    } catch (error) {
      if (error instanceof NoSessionIdError || error instanceof FinishedSessionError) {
        setupEntries();
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className={styles.posts}>
      <Header isBigViewport={isBigViewport} />

      {entry && !isLoading && (
        <Card
          key={entry.id}
          id={entry.id}
          translation={entry.translation}
          context={entry.context}
          src={entry.illustrations[currentSlideIndex].path}
          mediaType={entry.illustrations[currentSlideIndex].type}
          slidesCount={entry.illustrations.length}
          sourceLink={entry.source}
          currentSlideIndex={currentSlideIndex}
          onSlideSelect={setCurrentSlideIndex}
          onCardClick={() =>
            setCurrentSlideIndex(
              currentSlideIndex !== entry.illustrations.length - 1 ? currentSlideIndex + 1 : 0,
            )
          }
          onPrevSlideClick={() => setCurrentSlideIndex(state => Math.max(0, state - 1))}
          onNextSlideClick={() =>
            setCurrentSlideIndex(state => Math.min(state + 1, entry.illustrations.length - 1))
          }
          onSwipeLeft={handleClickNext}
          onSwipeRight={handleClickPrev}
          isBigViewport={isBigViewport}
        />
      )}

      {!isLoading && !entry && <p className={styles.error}>Something went wrong</p>}

      {isLoading && <IndeterminateProgress />}

      <Controls
        hasPrev={currentEntryIndex > 0}
        onPrevClick={handleClickPrev}
        onNextClick={handleClickNext}
        disabled={isLoading || !entry}
        entryId={entry?.id}
      />
    </main>
  );
};
