import { FC, useMemo } from 'react';
import cn from 'classnames';
import * as styles from './SlidesCountIndicator.module.scss';

interface Props {
  length: number;
  activeIndex: number;
  onSlideSelect: (index: number) => void;
  className?: string;
}

export const SlidesCountIndicator: FC<Props> = ({
  length,
  activeIndex,
  onSlideSelect,
  className,
}) => (
  <div className={cn(styles.slidesCountIndicator, className)}>
    {useMemo(
      () =>
        Array.from({ length }, (_, index) => (
          <button
            key={index}
            type="button"
            className={cn(styles.countIndicatorWrapper, {
              [styles.isActive]: index === activeIndex,
            })}
            onClick={() => onSlideSelect(index)}
          >
            <span className={styles.countIndicator} />
          </button>
        )),
      [length, activeIndex],
    )}
  </div>
);
