.card {
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0 auto;
  border-radius: 12px;
  overflow-y: auto;

  display: grid;
  grid-template-rows: max-content 1fr;
  transition: transform 200ms ease-out;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin: 24px auto;
    flex-wrap: nowrap;
    width: 77%;
    height: 88%;
  }
}

.cardLeft {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 16px 24px;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 50%;
  }
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.cardArrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  color: #e41b1b;
  background-color: #fff;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  animation: fadeIn 300ms ease-in;
  transition: transform 100ms ease, background-color 100ms ease;

  @media (min-width: 768px) {
    padding: 8px;
  }

  &:hover {
    background-color: #fff;
    transform: scale(1.04);
  }

  &:active {
    transform: scale(1);
  }

  &:disabled {
    cursor: default;
    background-color: transparent;
  }
}

.cardArrowIcon {
  transition: opacity 300ms;

  .cardArrow:disabled & {
    opacity: 0.5;
  }
}

.cardArrowLeft {
  left: 0;

  @media (min-width: 768px) {
    left: 4px;
  }
}

.cardArrowRight {
  right: 0;

  @media (min-width: 768px) {
    right: 4px;
  }
}

.cardImageWrapper {
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  max-height: 60vh;
  min-height: 25vh;

  cursor: default;

  .hasMultipleSlides & {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    max-height: initial;
  }
}

.media {
  max-width: 100%;
  max-height: 66vh;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  object-fit: contain;
  animation: fadeIn 300ms ease-in;

  @media (min-width: 768px) {
    max-height: 100%;
  }
}

.slidesCountIndicator {
  margin-top: 12px;
}

.translation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
    background-color: #3d3e43;
  }
}

.translationTextWrapper {
  height: 100%;
  position: relative;
  overflow: hidden;

  @media (min-width: 768px) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 8px;
      height: 20px;
      background: linear-gradient(to top, rgb(#3d3e43, 0), #3d3e43);
      z-index: 1;
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 8px;
      height: 20px;
      background: linear-gradient(to bottom, rgb(#3d3e43, 0), #3d3e43);
      z-index: 1;
      pointer-events: none;
    }
  }
}

.translationText {
  width: 100%;
  height: 100%;
  padding: 12px 16px 40px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-y: auto;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #f4f5f6;

  @media (min-width: 768px) {
    padding: 12px 16px;
  }

  .translation:hover & {
    color: #fff;
  }

  &::selection {
    background-color: #e03c4e;
    color: #fff;
  }
}

.translationBottom {
  display: none;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px 12px;
  margin-top: 4px;

  @media (min-width: 768px) {
    display: flex;
  }
}

.shareButton {
  min-width: 120px; // prevent jump of "copied" state
  background: #fff;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #e41b1b;
  cursor: pointer;
  outline: none;
  transition: background-color 300ms ease;

  @media (min-width: 768px) {
    background: #ebecf0;
  }

  &:hover {
    background-color: #fff;
  }

  &:focus-visible {
    outline: #e41b1b auto 1px;
  }
}

.shareContent {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.sourceBlock {
  display: grid;
  grid-gap: 4px;
  margin-right: 8px;
}

.sourceTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #a2a3aa;
}

.sourceLink {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ef7676;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tooltip {
  display: grid;
  grid-gap: 8px;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
}

.tooltipTitle {
  font-weight: 700;
  letter-spacing: 0.03em;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
