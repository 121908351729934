import { FC } from 'react';
import * as styles from './IndeterminateProgress.module.scss';

export const IndeterminateProgress: FC = () => (
  <div className={styles.progressLoader}>
    <div className={styles.progressBar}>
      <div className={styles.progressValue} />
    </div>
  </div>
);
