.controls {
  //align-self: flex-end;
  position: fixed;
  left: 16px;
  right: 16px;
  bottom: 12px;
  //background: rgb(#242528, 0.75);

  @media (min-width: 768px) {
    position: static;
    background-color: transparent;
    justify-content: center;
    align-self: center;
    grid-gap: 16px;
  }
}

.controlsNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;

  //background-color: rgba(255, 255, 255, 0.12);
  background-color: #3f4042;
  border-radius: 16px;

  @media (min-width: 768px) {
    background-color: transparent;
    justify-content: center;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    border-radius: initial;
  }
}

.controlButton {
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: capitalize;
  transition: color 200ms ease, opacity 100ms ease;

  &.hiddenOnMobile {
    opacity: 0;
    pointer-events: none;

    @media (min-width: 768px) {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &:disabled {
    cursor: default;
  }
}

.shareButton {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}

.arrowIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #fff;
  color: #e41b1b;
  border-radius: 12px;
  transition: transform 100ms ease, background-color 100ms ease;

  @media (min-width: 768px) {
    background-color: #ebecf0;
  }

  .controlButton:hover & {
    transform: scale(1.02);
    background-color: #fff;
  }

  .controlButton:active & {
    transform: scale(1);
  }

  .controlButton:disabled & {
    transform: scale(1);
    background-color: #797979;
    color: #fff;
  }

  .controlButton:focus-visible & {
    outline: #e41b1b auto 1px;
  }
}

.shareButtonContent {
  width: 112px;
  height: 48px;
  font-weight: 700;
  padding: 12px 20px 12px 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  justify-content: flex-start;
  align-items: center;
}

.controlButtonText {
  display: none;
  margin-top: 4px;

  color: #fff;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  transition: color 150ms ease;

  @media (min-width: 768px) {
    display: block;
    color: #ebecf0;

    .controlButton:hover & {
      color: #fff;
    }
  }

  .controlButton:disabled & {
    color: #a2a3aa;
  }
}

.arrows {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;

  @media (min-width: 768px) {
    grid-gap: 16px;
  }
}
