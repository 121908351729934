.menu .menuButton {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  margin: 0;
  border: 1px solid rgba(255, 255, 255, 0.24);

  &:hover {
    background-color: #3d3e43;
    border-color: transparent;
  }

  &.isOpen {
    background-color: #3d3e43;
  }

  @media (min-width: 768px) {
    border: none;
    transition: background-color 200ms ease, transform 200ms;

    &:hover {
      transform: scale(1.05);
    }

    &.isOpen {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
  }
}

.menu .menuContent {
  background-color: #3d3e43;
  padding: 4px 0;
  border-radius: 12px;
  min-width: 190px;
  z-index: 10000000; // over scroll
}

.menu .menuItem {
  padding: 8px 12px;
  background-color: transparent !important;
}

.menu .link {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
  justify-content: flex-start;
  align-items: center;
}

.menuItemText {
  transition: color 100ms;
  color: #ebecf0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;

  .menuItem:hover & {
    color: #fff;
  }
}

.icon {
  stroke: #ebecf0;
  transition: transform 100ms;

  .menuButton:hover & {
    stroke: #fff;
    transform: scale(1.08);
  }

  .menuItem:hover & {
    stroke: #fff;
    transform: scale(1.08);
  }
}

.hoverIcon {
  display: none;
  stroke: #e41b1b !important;

  .menuButton:hover & {
    display: block;
  }

  .menuItem:hover & {
    display: block;
  }
}

.notHoverIcon {
  .menuButton:hover & {
    display: none;
  }

  .menuItem:hover & {
    display: none;
  }
}

.menu .divider {
  background-color: #f6d015;
  height: 2px;
  position: relative;

  &::before {
    content: '';
    background-color: #0475ed;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 50%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
