import { FC } from 'react';
import { PopoverMenu } from '../components/Menu/Menu';
import * as styles from './Header.module.scss';

interface Props {
  isBigViewport: boolean;
}

export const Header: FC<Props> = ({ isBigViewport }) => (
  <header className={styles.header}>
    <div className={styles.top}>
      <PopoverMenu className={styles.menu} isBigViewport={isBigViewport} />

      <div className={styles.logo}>
        <p className={styles.first}>Not</p>
        <p className={styles.second}>All Russians</p>
      </div>
    </div>
  </header>
);
