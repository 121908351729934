import cn from 'classnames';
import { FC, forwardRef, ReactNode, useCallback } from 'react';
import { noop } from '../../utils/common';
import { useCopyToClipboard } from './CopyButton.helpers';

interface Props {
  value: string;
  title?: string;
  onCopy?: () => void;
  disabled?: boolean;

  children: ReactNode;
  className?: string;

  copiedChildren?: ReactNode;
  copiedClassName?: string;

  canUseNavigatorShare: boolean;
}

export const CopyButton: FC<Props> = forwardRef<HTMLButtonElement, Props>(
  (
    {
      title,
      value,
      onCopy = noop,
      disabled = false,

      children,
      className = '',

      copiedChildren = null,
      copiedClassName = '',
      canUseNavigatorShare,

      ...otherProps
    },
    ref,
  ) => {
    const [isCopied, copy] = useCopyToClipboard();

    const handleClick = useCallback(() => {
      const callback = () => {
        copy(value);
        onCopy();
      };

      if (canUseNavigatorShare && navigator.share) {
        navigator
          .share({
            title,
            url: value,
          })
          .then(callback)
          .catch(callback);
      } else {
        callback();
      }
    }, [canUseNavigatorShare, value, copy, onCopy]);

    return (
      <button
        type="button"
        className={cn(className, {
          [copiedClassName]: isCopied,
        })}
        onClick={handleClick}
        disabled={disabled}
        {...otherProps}
        ref={ref}
      >
        {isCopied ? copiedChildren ?? children : children}
      </button>
    );
  },
);
