.loader {
  height: 60px;
  width: 60px;
  position: relative;
  animation: spin 0.5s infinite steps(10);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loader span {
  position: absolute;
  border-radius: 50% 50% 0 0;
  height: 25%;
  width: 5%;
  background-color: #e41b1b;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(calc(((360 / 10) * var(--index)) * 1deg))
    translate(0, 100%);
  opacity: calc(var(--index) / 10);
}
