.progressLoader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.progressBar {
  height: 2px;
  width: 100%;
  overflow: hidden;
}

.progressValue {
  border-radius: 0 25% 25% 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0%) scaleX(0%);
  }
  40% {
    transform: translateX(0%) scaleX(40%);
    background-color: #e41b1b;
  }
  100% {
    transform: translateX(100%) scaleX(50%);
  }
}
