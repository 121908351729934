import { createRoot } from 'react-dom/client';
import { App } from './App/App';
import { BrowserRouter } from 'react-router-dom';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

const app = document.getElementById('app');
const root = createRoot(app);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
