.root {
  background-color: rgb(51, 51, 51);
  color: #ebecf0;
  border-radius: 4px;
  border: 1px solid #4e4e4e;
  padding: 12px 16px;
  font-size: 13px;
  z-index: 1;
}

.arrow {
  & path {
    fill: rgb(51, 51, 51);
  }
}
