import { TouchEvent, useCallback, useEffect, useRef, useState } from 'react';

export const useViewport = (): boolean => {
  const [isBigViewport, setIsBigViewport] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const desktopMatchMedia = window.matchMedia('(min-width: 768px)');
    const mediaQueryCallback = (event: MediaQueryListEvent) => setIsBigViewport(event.matches);

    const isSafari = Boolean(window.navigator.userAgent.toLowerCase().match(/safari/));
    const isWebkitListener =
      isSafari && !desktopMatchMedia.addEventListener && desktopMatchMedia.addListener;
    const listener: any = isWebkitListener
      ? desktopMatchMedia.addListener(mediaQueryCallback)
      : desktopMatchMedia.addEventListener('change', mediaQueryCallback);

    return () => {
      if (isWebkitListener) {
        listener?.removeListener(mediaQueryCallback);
      } else {
        listener?.removeEventListener(mediaQueryCallback);
      }
    };
  });

  return isBigViewport;
};

export const useSwipes = (onSwipeLeft: () => void, onSwipeRight: () => void) => {
  const cardRef = useRef<HTMLDivElement>();
  const touchStartX = useRef<number>(null);
  const touchStartY = useRef<number>(null);

  const onTouchStart = useCallback((event: TouchEvent<HTMLDivElement>) => {
    touchStartX.current = event.changedTouches[0].screenX;
    touchStartY.current = event.changedTouches[0].screenY;
  }, []);

  const onTouchMove = useCallback((event: TouchEvent<HTMLDivElement>) => {
    if (!touchStartX.current || !touchStartY.current) {
      return;
    }

    const { screenX } = event.changedTouches[0];

    const diff = screenX - touchStartX.current;
    cardRef.current.style.transform = `translateX(${diff}px)`;
  }, []);

  const onTouchEnd = useCallback(
    (event: TouchEvent<HTMLDivElement>) => {
      if (!touchStartX.current || !touchStartY.current) {
        return;
      }

      const { screenX, clientX } = event.changedTouches[0];

      const diff = Math.ceil(Math.abs(screenX - touchStartX.current));

      cardRef.current.style.transform = `translateX(0px)`;

      if (diff < 40) {
        return;
      }

      if (document.body.clientWidth - clientX < 85 && screenX > touchStartX.current) {
        onSwipeRight();
      } else if (clientX < 85 && screenX < touchStartX.current) {
        onSwipeLeft();
      }
    },
    [onSwipeLeft, onSwipeRight],
  );

  return { cardRef, onTouchStart, onTouchMove, onTouchEnd };
};
