import cn from 'classnames';
import { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BookOpen, Heart, Home, Menu as MenuIcon, PlusSquare, Shield } from 'react-feather';
import { Menu, MenuButton, MenuDivider, MenuItem } from '@szhsin/react-menu';
import * as styles from './Menu.module.scss';

interface Props {
  className?: string;
  isBigViewport: boolean;
}

export const PopoverMenu: FC<Props> = ({ isBigViewport, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return (
    <div className={cn(styles.menu, className)}>
      <Menu
        onMenuChange={state => setIsOpen(state.open)}
        offsetY={10}
        offsetX={1}
        direction="bottom"
        align={isBigViewport ? 'start' : 'end'}
        transition={true}
        menuClassName={styles.menuContent}
        menuButton={
          <MenuButton
            className={cn(styles.menuButton, {
              [styles.isOpen]: isOpen,
            })}
          >
            <MenuIcon className={styles.icon} />
          </MenuButton>
        }
      >
        {!location.pathname.includes('posts') && (
          <MenuItem className={styles.menuItem}>
            <Link to="/posts" className={styles.link}>
              <Home className={styles.icon} strokeWidth={2} />
              <span className={styles.menuItemText}>Home</span>
            </Link>
          </MenuItem>
        )}

        {!location.pathname.includes('about') && (
          <MenuItem className={styles.menuItem}>
            <Link to="/about" className={styles.link}>
              <BookOpen className={styles.icon} strokeWidth={2} />
              <span className={styles.menuItemText}>About</span>
            </Link>
          </MenuItem>
        )}

        <MenuItem className={styles.menuItem}>
          <a href="https://t.me/notallrussians_bot" target="_blank" className={styles.link}>
            <PlusSquare className={styles.icon} strokeWidth={2} />
            <span className={styles.menuItemText}>Submit posts</span>
          </a>
        </MenuItem>

        <MenuDivider className={styles.divider} />

        <MenuItem className={styles.menuItem}>
          <a href="https://www.comebackalive.in.ua/donate" target="_blank" className={styles.link}>
            {isBigViewport ? (
              <>
                <Shield className={cn(styles.icon, styles.notHoverIcon)} strokeWidth={2} />
                <Heart className={cn(styles.icon, styles.hoverIcon)} strokeWidth={2} />
              </>
            ) : (
              <Heart className={styles.icon} strokeWidth={2} />
            )}

            <span className={styles.menuItemText}>Support Ukraine</span>
          </a>
        </MenuItem>
      </Menu>
    </div>
  );
};
