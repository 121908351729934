import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

export const useCopyToClipboard = (
  duration: number = 2_000,
): [boolean, Dispatch<SetStateAction<string>>] => {
  const [isCopied, setCopied] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleCopy = useCallback(
    value => {
      navigator.clipboard.writeText(value).then(() => {
        setCopied(true);
      });

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => setCopied(false), duration);
    },
    [duration],
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return [isCopied, handleCopy];
};
