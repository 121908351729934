import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { ArrowLeft, ArrowRight, Check, Share } from 'react-feather';
import { useSwipes } from '../utils/hooks';
import { PUBLIC_URL } from '../utils/constants';
import { MediaType } from '../types';
import { SlidesCountIndicator } from '../SlidesCountIndicator/SlidesCountIndicator';
import { Tooltip } from '../components/Tooltip/Tooltip';
import { Loader } from '../components/Loader/Loader';
import { CopyButton } from '../components/CopyButton/CopyButton';
import * as styles from './Card.module.scss';

interface Props {
  id: string;
  onCardClick: () => void;
  translation: string;
  context: string;

  slidesCount: number;
  currentSlideIndex: number;

  mediaType: MediaType;
  src: string;

  onPrevSlideClick: () => void;
  onNextSlideClick: () => void;
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
  onSlideSelect: (index: number) => void;

  sourceLink?: string;

  isBigViewport: boolean;
}

export const Card: FC<Props> = ({
  id,
  onCardClick,
  onSwipeLeft,
  onSwipeRight,
  onPrevSlideClick,
  onNextSlideClick,
  mediaType,
  src,
  translation,
  context,
  slidesCount,
  currentSlideIndex,
  onSlideSelect,
  sourceLink,
  isBigViewport,
}) => {
  const hasMultipleSlides = slidesCount > 1;
  const [isLoading, setIsLoading] = useState(false);
  const { cardRef, onTouchStart, onTouchMove, onTouchEnd } = useSwipes(onSwipeLeft, onSwipeRight);

  useEffect(() => {
    setIsLoading(true);

    if (mediaType === MediaType.VIDEO) {
      setIsLoading(false);
    } else {
      const image = new Image();
      image.src = src;
      image.onload = () => setIsLoading(false);
    }
  }, []);

  return (
    <div
      ref={cardRef}
      className={styles.card}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <div
        className={cn(styles.cardLeft, {
          [styles.hasMultipleSlides]: hasMultipleSlides,
        })}
      >
        <button
          className={styles.cardImageWrapper}
          type="button"
          onClick={onCardClick}
          disabled={!hasMultipleSlides}
        >
          {isLoading && (
            <div className={styles.loader}>
              <Loader delayMs={150} />
            </div>
          )}

          {mediaType === MediaType.VIDEO ? (
            <video
              className={styles.media}
              src={src}
              width="100%"
              height="100%"
              autoPlay={true}
              controls={true}
              loop={true}
            />
          ) : (
            <img className={styles.media} src={src} alt="" />
          )}
        </button>

        {hasMultipleSlides && (
          <>
            <button
              type="button"
              className={cn(styles.cardArrow, styles.cardArrowLeft)}
              onClick={onPrevSlideClick}
              disabled={currentSlideIndex === 0}
            >
              <ArrowLeft className={styles.cardArrowIcon} strokeWidth={2.5} />
            </button>

            <button
              type="button"
              className={cn(styles.cardArrow, styles.cardArrowRight)}
              onClick={onNextSlideClick}
              disabled={currentSlideIndex >= slidesCount - 1}
            >
              <ArrowRight className={styles.cardArrowIcon} strokeWidth={2.5} />
            </button>
          </>
        )}

        {hasMultipleSlides && (
          <SlidesCountIndicator
            className={styles.slidesCountIndicator}
            length={slidesCount}
            activeIndex={currentSlideIndex}
            onSlideSelect={onSlideSelect}
          />
        )}
      </div>
      <div className={styles.translation}>
        <div className={styles.translationTextWrapper}>
          {!isLoading && (
            <div className={styles.translationText}>
              <strong>Translation:</strong>
              <p>{translation}</p>

              {context && (
                <>
                  <br />
                  <strong>Context:</strong>
                  <p>{context}</p>
                </>
              )}
            </div>
          )}
        </div>

        <div className={styles.translationBottom}>
          <Tooltip
            text={
              <div className={styles.tooltip}>
                <p className={styles.tooltipTitle}>Copy to clipboard</p>
                <p>Share this post with your friends</p>
              </div>
            }
          >
            <CopyButton
              canUseNavigatorShare={!isBigViewport}
              className={styles.shareButton}
              value={`${PUBLIC_URL}/posts/${id}`}
              title="URL with current post"
              copiedChildren={
                <span className={styles.shareContent}>
                  <Check /> Copied
                </span>
              }
            >
              <span className={styles.shareContent}>
                <Share />
                <span>Share</span>
              </span>
            </CopyButton>
          </Tooltip>

          {sourceLink && (
            <p className={styles.sourceBlock}>
              <span className={styles.sourceTitle}>Source: </span>
              {'  '}
              <a className={styles.sourceLink} href={sourceLink} target="_blank">
                <span>{sourceLink}</span>
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
