import { useLayer, useHover, Arrow } from 'react-laag';
import { cloneElement, FC, ReactElement, ReactNode } from 'react';
import { isReactText } from './Tooltip.helpers';
import * as styles from './Tooltip.module.scss';

interface Props {
  children: ReactElement;
  text: ReactNode;
  force?: boolean;
}

export const Tooltip: FC<Props> = ({ children, text, force = false }) => {
  const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 300 });

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver || force,
    placement: 'top-center',
    triggerOffset: 16,
  });

  const trigger = isReactText(children) ? (
    <span className="tooltip-text-wrapper" {...triggerProps} {...hoverProps}>
      {children}
    </span>
  ) : (
    cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
    })
  );

  return (
    <>
      {trigger}
      {renderLayer(
        (isOver || force) && (
          <div className={styles.root} {...layerProps}>
            {text}
            <Arrow
              {...arrowProps}
              size={6}
              borderColor="#4e4e4e"
              borderWidth={1}
              roundness={1}
              className={styles.arrow}
            />
          </div>
        ),
      )}
    </>
  );
};
