export enum MediaType {
  PHOTO = 'photo',
  VIDEO = 'video',
}

export interface Illustration {
  id: string;
  entry_id: string;
  path: string;
  size: string;
  type: MediaType;
}

export interface Entry {
  id: string;
  context: string;
  translation: string;
  illustrations: Illustration[];
  is_verified: boolean;
  source: string;
  submitted_at: Date;
  updated_at: Date;
}
