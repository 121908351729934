import cn from 'classnames';
import { FC } from 'react';
import { ArrowLeft, ArrowRight, Check, Share } from 'react-feather';
import { CopyButton } from '../components/CopyButton/CopyButton';
import { PUBLIC_URL } from '../utils/constants';
import * as styles from './Controls.module.scss';

interface Props {
  disabled: boolean;
  hasPrev: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
  entryId: string;
}

export const Controls: FC<Props> = ({ entryId, disabled, hasPrev, onPrevClick, onNextClick }) => (
  <div className={styles.controls}>
    <nav className={styles.controlsNav}>
      <CopyButton
        canUseNavigatorShare={true}
        className={cn(styles.controlButton, styles.shareButton)}
        title="URL with current post"
        value={`${PUBLIC_URL}/posts/${entryId}`}
        disabled={!entryId}
        copiedChildren={
          <div className={cn(styles.arrowIconWrapper, styles.shareButtonContent)}>
            <Check strokeWidth={2.5} /> Copied
          </div>
        }
      >
        <div className={cn(styles.arrowIconWrapper, styles.shareButtonContent)}>
          <Share strokeWidth={2.5} /> Share
        </div>
      </CopyButton>

      <div className={styles.arrows}>
        <button
          type="button"
          className={cn(styles.controlButton, {
            [styles.hiddenOnMobile]: !hasPrev,
          })}
          disabled={disabled}
          onClick={onPrevClick}
        >
          <div className={styles.arrowIconWrapper}>
            <ArrowLeft strokeWidth={2.5} />
          </div>

          <span className={styles.controlButtonText}>Prev</span>
        </button>

        <button
          type="button"
          className={styles.controlButton}
          disabled={disabled}
          onClick={onNextClick}
        >
          <div className={styles.arrowIconWrapper}>
            <ArrowRight strokeWidth={2.5} />
          </div>

          <span className={styles.controlButtonText}>Next</span>
        </button>
      </div>
    </nav>
  </div>
);
