import { FC } from 'react';
import { Header } from '../Header/Header';
import * as styles from './About.module.scss';

interface Props {
  isBigViewport: boolean;
}

export const About: FC<Props> = ({ isBigViewport }) => (
  <div className={styles.about}>
    <Header isBigViewport={isBigViewport} />

    <article className={styles.content}>
      <section className={styles.section}>
        <p className={styles.sectionTitle}>About</p>
        <p className={styles.text}>
          Russia started the full-scale invasion of Ukraine on the morning of{' '}
          <strong>24 February 2022</strong>. With the physical bombings and troops on the ground
          came full-scale media attacks across numerous fronts. The Russian propaganda machine works
          on multiple levels. It produces not only easy-to-debunk and straightforward fakes but also
          creates much more subtle and hard-to-spot narratives. One of such stories is about{' '}
          <strong>"ordinary Russians"</strong> who do not support their government and suffer from
          unjustified sanctions. You can see people worldwide repeating the same three words -{' '}
          <strong>"not all russians"</strong>.
        </p>
        <br />
        <p className={styles.text}>
          On all platforms, you can find individuals sympathizing with Russians and putting all of
          the blame on the Russian government. It went so far that even chancellor of Germany Olaf
          Sholz expressed sympathy to <strong>"young Russians soldiers"</strong> that kill, rape,
          and torture Ukrainians on their soil.{' '}
          <span className={styles.linkItem}>
            Link:{' '}
            <a
              href="https://www.instagram.com/p/CbNSdn2IkFr/"
              target="_blank"
              className={styles.link}
            >
              https://www.instagram.com/p/CbNSdn2IkFr/
            </a>
          </span>
        </p>
        <br />
        <p className={styles.text}>
          Such statements do not reflect the whole picture, are incredibly disrespectful to
          Ukrainians, and harm war-stopping efforts such as sanctions and economic boycott of the
          aggressor. Multiple government-backed and independent polls show robust and firm support
          for atrocities in Ukraine, which leaves a vast and bloody footprint across social
          platforms.{' '}
          <span className={styles.linkItem}>
            Link:{' '}
            <a
              href="https://www.atlanticcouncil.org/blogs/ukrainealert/not-just-putin-most-russians-support-the-war-in-ukraine/"
              target="_blank"
              className={styles.link}
            >
              https://www.atlanticcouncil.org/blogs/ukrainealert/not-just-putin-most-russians-support-the-war-in-ukraine/
            </a>
          </span>
        </p>
        <br />
        <p className={styles.text}>
          This project arose from multiple conversations with foreigners about{' '}
          <strong>"regular Russians"</strong>. Usually, such discussions fall into a similar
          routine: sharing the same links, showing the same screenshots, and copy-pasting the same
          arguments. This website attempts to bring together these resources and show the true face
          of Russian society. As a product of centuries of linguistic imperialism in Ukraine, we can
          quickly navigate the moody waters of Russian social media and translate thoughts of real
          "ordinary Russians" to our foreign friends.
        </p>
      </section>

      <section className={styles.section}>
        Feel free to join us on{' '}
        <a className={styles.link} href="https://twitter.com/notallrussians" target="_blank">
          twitter
        </a>
        ,{' '}
        <a className={styles.link} href="https://t.me/notallrussians" target="_blank">
          telegram
        </a>
        , or{' '}
        <a className={styles.link} href="https://t.me/notallrussians_bot" target="_blank">
          submit new posts
        </a>
        .
      </section>
    </article>
  </div>
);
