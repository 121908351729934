import { FC } from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { Posts } from '../Posts/Posts';
import { About } from '../About/About';
import * as styles from './App.module.scss';

export const App: FC = () => (
  <div className={styles.root}>
    <Routes>
      <Route path="/about" element={<About />} />
      <Route path="/posts" element={<Posts />} />
      <Route path="/posts/:postId" element={<NavigateToPostsWithPostId />} />
      <Route path="*" element={<Navigate replace to="/posts" />} />
    </Routes>
  </div>
);

function NavigateToPostsWithPostId() {
  const { postId } = useParams();

  return <Navigate replace to="/posts" state={postId} />;
}
