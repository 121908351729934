.about {
  width: 100vw;
  padding: 12px 16px 0;
  display: grid;
  grid-template-rows: min-content auto;

  @media (min-width: 768px) {
    height: 100vh;
  }
}

.content {
  overflow-y: auto;
  margin-top: 12px;
  padding-bottom: 12px;
}

.text {
  color: #f4f5f7;
  line-height: 28px;
  letter-spacing: 0.01em;

  &::selection {
    background-color: #e03c4e;
    color: #fff;
  }
}

.section {
  width: 90%;
  margin: 20px auto 0;

  @media (min-width: 768px) {
    width: 70%;
  }
}

.sectionTitle {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
  text-align: center;

  &::selection {
    background-color: #e03c4e;
    color: #fff;
  }
}

.link {
  text-decoration: none;
  word-break: break-all;
  border-bottom: 1px solid #e03c4e;
  color: #fff;
  transition: color 200ms ease, background-color 200ms ease;

  &:hover {
    background-color: #e03c4e;
    color: #fff;
  }

  &::selection {
    background-color: #e03c4e;
    color: #fff;
  }
}

.linkItem {
  display: block;

  @media (min-width: 768px) {
    display: inline;
  }
}
