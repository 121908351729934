import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import * as styles from './Loader.module.scss';

interface Props {
  delayMs?: number;
  className?: string;
}

Array.from({ length: 10 }, () => 1);

export const Loader: FC<Props> = ({ className = '', delayMs = 0 }) => {
  const [canShow, setCanShow] = useState(delayMs === 0);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (delayMs > 0) {
      timeout = setTimeout(() => {
        setCanShow(true);
      }, delayMs);
    }

    return () => timeout && clearTimeout(timeout);
  }, []);

  const items = useMemo(
    () =>
      Array.from({ length: 10 }, (_, i) => (
        <span key={i} style={{ '--index': i } as CSSProperties} />
      )),
    [],
  );

  if (!canShow) {
    return null;
  }

  return <div className={cn(styles.loader, className)}>{items}</div>;
};
