.header {
  font-size: 28px;
  font-weight: 700;
  color: #252524;

  display: grid;
  grid-gap: 12px;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    margin: 0;
    font-size: 36px;
  }
}

.top {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
    grid-template-columns: 1fr auto 1fr;
  }
}

.menu {
  margin-left: auto;

  @media (min-width: 768px) {
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.logo {
  cursor: default;
  display: grid;
  grid-gap: 4px;
  text-align: left;

  order: -1;

  @media (min-width: 768px) {
    text-align: center;
    order: 1;
  }
}

.first {
  color: #fff;
  text-decoration: line-through;

  @media (min-width: 768px) {
    text-transform: uppercase;
  }
}

.second {
  color: #e41b1b;
}
