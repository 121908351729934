.posts {
  flex-direction: column;
  padding: 12px 16px;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;

  display: grid;
  grid-template-rows: min-content auto 32px;
  overflow: hidden;

  @media (min-width: 768px) {
    max-height: initial;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
  }
}

.error {
  padding: 16px;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #e41b1b;
  font-size: 20px;
  margin: auto;
  width: fit-content;
  border-radius: 24px;
  animation: fadeIn 500ms ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
