.slidesCountIndicator {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2px;
  justify-content: center;
  align-items: center;
  animation: fadeIn 300ms ease-in;
}

.countIndicatorWrapper {
  padding: 2px;
  cursor: pointer;
}

.countIndicator {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(24, 24, 24, 0.54);
  border: 1px solid rgba(24, 24, 24, 0.18);
  transition: background-color 200ms, transform 200ms;

  .isActive & {
    background-color: #e41b1b;
  }

  &:hover {
    transform: scale(1.15);
    background-color: #f19797 !important;
  }

  &:active {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
